.content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-filter {
    overflow: auto;
    height: auto;
    max-height: 300px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.table-filter-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-step-header {
    margin-left: 16px;
    vertical-align: middle;
}

.save-job-selects {
    width: 90%;
    height: fit-content;
    text-align: center;
}

.save-job-select-env {
    width: max-content;
    height: fit-content;
    text-align: center;
}

.save-job-select {
    width: 100%;
    height: fit-content;
    text-align: center;
}
.save-job-select-email {
    width: 100% !important;
   
}
.centered-wrapper {
    width: 400px;
    height: fit-content;
    text-align: center;
    padding: 20px;
}
.centered-wrapper > .ant-form-item {
    margin-bottom: 10px !important;
}

.input-row {
    margin-bottom: 0px;
    margin-top: 10px;
}
.input-row-email {
    margin-bottom: 0px;
    margin-top: 10px;
}
.settings-item-list {
    cursor: pointer;
}

.ldap-dropdown,
.doc-row,
.login-button {
    width: 100%;
}

.create-new-project-button {
    width: fit-content;
}

.theme-changer {
    width: fit-content;
    position: absolute;
    top: 10px;
    right: 10px;
}

.lang-changer {
    width: fit-content;
    position: absolute;
    top: 10px;
    right: 80px;
}

.ldap-dropdown-element {
    float: left;
}

.project-list-buttons {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.icon-only-button {
    box-shadow: none;
    border: none;
}

.text-align-center {
    text-align: center;
}

.project-box {
    cursor: pointer;
}

.config-header {
    padding: 0px;
}

.doc-button:hover {
    transform: scale(1.2);
    border-color: transparent;
}

.doc-content {
    display: flex;
    position: relative;
    text-align: initial;
    width: 100%;
    height: 100%;
}

.doc-operations {
    width: 80%;
}

.doc-text,
.diff-editor-node-title {
    float: right;
}

.git-diff-left-tree .ant-tree-title {
    width: 100%;
}

.file-modal-alert,
.project-settings-modal-alert,
.project-settings-modal-checkbox,
.project-settings-modal-language-row,
.project-settings-modal-dependency-input-row {
    margin-top: 10px;
}

.project-settings-modal-input-row {
    margin-top: 10px;
    width: 100%;
}

.project-settings-modal-input {
    width: 100%;
}

.project-settings-modal-col {
    width: 150px;
}

.project-settings-modal-icon {
    margin-left: 4px;
}
.project-settings-modal-dependency-label {
    font-size: small;
}

.right-panel-leaf-button {
    cursor: pointer;
    margin-left: 10px;
}

.right-panel-leaf-button:hover {
    transform: scale(1.2);
    border-color: transparent;
}

.search-result-list {
    max-height: 24vh;
    overflow: auto;
}

.search-result-list-item {
    border-radius: 10px;
}

.install-modal {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.save-job-input {
    width: 25%;
}

.runExample {
    left: 0px !important;
    height: 0px !important;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #7ac8f8;
    margin: 4px 0 0px 4px;
    display: block;
    cursor: pointer;
}

.runExample:hover {
    transform: scale(1.05);
    border-color: transparent transparent transparent #0276c9;
}

.runScenario,
.runFeature {
    left: 0px !important;
    height: 0px !important;
    border-style: solid;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent #60bf5c;
    margin: 2px 0 0px 4px;
    display: block;
    cursor: pointer;
}

.runScenario:hover,
.runFeature:hover {
    transform: scale(1.05);
    border-color: transparent transparent transparent green;
}
.duplicateName {
    color: red !important;
    font-weight: bold;
}

.errorIcon {
    left: 4px !important;
    width: 18px !important;
    background-color: red;
    border-color: red;
    border-radius: 100%;
    border-style: solid;
    border-width: 4px;
    display: block;
}
.errorIcon:before {
    content: "!";
    font-weight: 700;
    color: white;
    display: block;
    margin: -3px 0 0 1px;
}

.log-console-body {
    height: 20vh;
    overflow: auto;
}

.log-console-logs.dark {
    color: white;
}

.log-console-logs.light {
    color: black;
}

.log-console-logs {
    font-size: smaller;
}


.log-console-line {
    margin-bottom: 0.5em;
}
.log-console-line.fail {
    color:red
}

.runner {
    float: right;
}
.git-tools-buttons {
    float: left;
}

.branch-selector {
    padding-left: 2;
    padding-right: 0;
}

.dash-radio-button {
    margin-right: 15px;
}

.dashboard-select-team {
    width: 200px;
    height: fit-content;
    text-align: center;
    margin-right: 15px;
}

.item-active {
    background-color: #fce4b2 !important;
}

.project-page-settings {
    float: right;
    font-size: 24px;
}

.project-page-utils {
    position: relative;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.bug-report-radio-button {
    display: block;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
}
.step-container {
    border: 1px solid #6d6d6d;
    border-radius: 4px;
    padding: 8px 8px 8px 12px;
    margin-bottom: 10px;
}

.local-run-history-action-button,
.git-tools-button {
    margin-right: 5px;
    margin-left: 10px;
    font-size: 14px;
}
.secondary-text {
    font-size: 10px;
}
.collapse-header {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.conflict-checked:before {
    cursor: pointer;
    content: "\2713";
    font-size: x-large;
    display: inline-block;
    color: darkcyan;
    padding: 0 6px 0 6px;
}

.conflict-unchecked.header:before,
.conflict-checked.header:before,
.conflict-not-selected.header:before {
    font-size: 15px;
}

.conflict-unchecked:before {
    cursor: pointer;
    background-color: #cccccc;
    content: "";
    display: inline-block;
    vertical-align: top;
    line-height: 1em;
    border: 1px solid #999;
    border-radius: 0.3em;
    width: 1em;
    height: 1em;
    text-align: center;
    margin: 4px 6px 0 6px;
}

.conflict-not-selected:before {
    cursor: pointer;
    content: "\2717";
    font-size: x-large;
    display: inline-block;
    color: darkred;
    padding: 0 6px 0 6px;
}

.search-parameter-selected {
    color: #e2b626;
    margin-left: 5px;
}
.search-parameter-unselected {
    color: #cccccc;
    margin-left: 5px;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.diff-header {
    padding-left: 200px;
    margin-bottom: 10px;
    font-size: 15px;
    font-style: oblique;
}

.project-list-loading {
    min-height: 100vh;
    display: table-cell;
    vertical-align: middle;
}

.monaco-editor-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 20px;
}

.centered-trigger {
    align-self: center;
}
.hover-button {
    position: relative;
    height: 100px;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
.centered-trigger-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.environment-alert {
    margin-top: 20px;
}

.delete-from-kukla-warning {
    margin-top: 10px;
}

.padding-top-10 {
    padding-top: 10px;
}

.__json-pretty__ {
    line-height: 1.3;
    color: #66d9ef;
    overflow: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
}

.__json-pretty__ .__json-key__ {
    color: #f92672
}

.__json-pretty__ .__json-value__ {
    color: #a6e22e
}

.__json-pretty__ .__json-string__ {
    color: #fd971f
}

.__json-pretty__ .__json-boolean__ {
    color: #ac81fe
}

.__json-pretty-error__ {
    line-height: 1.3;
    color: #66d9ef;
    background: #272822;
    overflow: auto
}